.page-footer {
    &__cols {
        > * {
            @screen sp {
                &:nth-child(odd) {
                    text-align: right;
                }
            }
            @screen l {
                text-align: left !important;
            }
        }
    }
}
