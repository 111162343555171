.page-header {
    &__burger {
        &[aria-expanded="true"] {
            background-color: transparent;
        }
        &-bar {
            transition: transform 0.3s 0.3s theme('transitionTimingFunction.out-back');
            > span {
                transform-origin: center center;
                transition: transform 0.3s;
            }
            &:first-child {
                [aria-expanded="true"] & {
                    transition: transform 0.3s;
                    transform: translateY(8px);
                    > span {
                        transition: transform 0.3s 0.15s theme('transitionTimingFunction.out-back');
                        transform: rotate(45deg);
                    }
                }
            }
            &:nth-child(2) {
                [aria-expanded="true"] & {
                    > span {
                        transition: transform 0s 0.15s;
                        transform: scaleX(0);
                    }
                }
            }
            &:nth-child(3) {
                [aria-expanded="true"] & {
                    transition: transform 0.3s;
                    transform: translateY(-8px);
                    width: 100% !important;
                    > span {
                        transition: transform 0.3s 0.15s theme('transitionTimingFunction.out-back');
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}
