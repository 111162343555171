body {
    min-height: calc(100% + 1px);
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-300 ease-out-cubic;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

button, a {
    touch-action: manipulation;

    &[disabled] {
        cursor: default;
    }
}

[hidden]:not(:target) {
    display: none !important;
}


input:required {
    box-shadow: none;
}

html.no-outline * {
    outline: none;
}

select {
    appearance: none;
}

// Errors
.errors {
    @apply text-error;
}
.error *[class*="border"] {
    @apply border-error;
}

// Make sure that form elements, when focused via :target, have a little top padding relative to the viewport
input:not([type="hidden"]),
textarea,
select,
[aria-haspopup="listbox"],
[data-receipt] {
    scroll-margin-top: 80px;
}

[data-receipt] {
    outline: none !important;
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid:not(.blowout) {
    // Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}

// Prettier lazyload transition for hero images
.hero {
    .lazyload, .lazyloading {
        transform: scale(1.02, 1.02) translate(0, 1vmin);
        transform-origin: center bottom;
    }
    .lazyloaded {
        transition: opacity 0.5s theme('transitionTimingFunction.in-sine'), transform 1.5s theme('transitionTimingFunction.out-quad');
    }
}

// Content blocks
.content-block {
    margin-bottom: clamp(60px, 15vw, 150px);
    @screen l {
        margin-bottom: 150px;
    }
    // Make sure colored content-blocks have no bottom margin if it is the last block
    &[class*="bg-"]:last-child,
    &.hero:last-child {
        margin-bottom: 0;
    }
    &[class*="bg-"] + &[class*="bg-"] {
        margin-top: clamp(-150px, -15vw, -60px);
        @screen l {
            margin-top: -150px;
        }
    }
}

// Inline video
.videoloop {
    html.js &:not([hidden]) + picture,
    html.js &:not([hidden]) + img {
        display: none;
    }
}

// Reveals
html.js:not(.js-error):not(.is-preview.is-iframe) [data-reveal]:not([data-revealed]) {
    opacity: 0.001;
}
