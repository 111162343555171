.wysiwyg {

    p, ul, ol {
        margin-bottom: calc((22/16) * 1em);

        &:last-child {
            margin-bottom: 0;
        }
    }

    strong, b {
        @apply font-bold;
    }

    em, i {
        @apply italic;
    }

    a:not([class]) {
        @apply link;
    }

    h1,h2,h3,h4,h5,h6 {
        margin-top: 1.25em;
        margin-bottom: 0.75em;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    h1, h2 {
        @apply h2;
    }

    h3, h4, h5, h6 {
        @apply h3;
    }

    ul {
        list-style-type: '–';
        margin-left: 0.4em;
    }

    ol {
        list-style-type: decimal;
        margin-left: 1em;
    }

    li {
        display: list-item;
        position: relative;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul li {
        padding-left: 1em;
    }

    ol li {
        padding-left: 0.4em;
    }
}
